<template>

    <div class="ar-terrain" :class="getClasses">

        <div class="content">
            <desktop-landing v-if="!mobile"></desktop-landing>
            <img src="@/assets/images/ar-terrain/img-bg-mobile.webp" class="landing-bg" v-if="mobile">
            <transition name="fade" mode="out-in" v-if="mobile"> 
                <router-view :tablet="tablet"></router-view>
            </transition>
        </div>

        <div class="orientation"><img src="@/assets/images/global/img-orientation.png"></div>

    </div>

</template>


<script>
import { isMobile, isTablet, isBrowser } from 'mobile-device-detect'
import DesktopLanding from '@/components/ar-terrain/DesktopLanding'

export default {

    name: 'ArTerrain',

    metaInfo() {
        return {
            title: 'Planning Your Route',
            meta: [
                {
                    name: 'description',
                    content: 'Use this augmented reality map to help you plan your route to Everest\'s summit.'
                }, {
                    name: 'keywords',
                    content: 'national geographic, natgeo, everest, mt everest, mt. everest, mount everest, climbing, summit, summiting, explore, ar, augmented reality, 8th wall, 3d, mountain, routes'
                }, {
                    property: 'og:title',
                    content: 'Planning Your Route'
                }, {
                    property: 'og:type',
                    content: 'website'
                }, {
                    property: 'og:url',
                    content: process.env.VUE_APP_BASE_URL + 'shares/planning-your-route.html'
                }, {
                    property: 'og:image',
                    content: process.env.VUE_APP_BASE_URL + 'img/shares/Everest_metaimage-facebook_v01_ls_0003_PlanRoute.jpg'
                }, {
                    property: 'site_name',
                    content: 'National Geographic : Everest Educational Interactives'
                }, {
                    property: 'og:description',
                    content: 'Use this augmented reality map to help you plan your route to Everest\'s summit.'
                }, {
                    property: 'twitter:card',
                    content: 'summary_large_image'
                }, {
                    property: 'twitter:url',
                    content: process.env.VUE_APP_BASE_URL + 'shares/planning-your-route.html'
                }, {
                    property: 'twitter:title',
                    content: 'Planning Your Route'
                }, {
                    property: 'twitter:description',
                    content: 'Use this augmented reality map to help you plan your route to Everest\'s summit.'
                }, {
                    property: 'twitter:image',
                    content: process.env.VUE_APP_BASE_URL + 'img/shares/Everest_metaimage-twitter_2-1_v01_ls_0003_PlanRoute.jpg'
                }
            ]
        }
    },

    components: {
        DesktopLanding
    },

    data() {
        return {
            componentName: this.$options.name + ' View',
            mobile: isMobile,
            tablet: isTablet,
            browser: isBrowser
        }
    },

    computed: {
        getClasses: function() {
            let el = this.tablet ? 'tablet' : ''
            el = this.mobile ? 'mobile' : ''
            if(this.browser && !this.tablet) {
                el += ' browser'
            }
            return el
        }
    },

    created() {
        if (this.mobile) { 
            let scripts = [
                //"//cdn.8thwall.com/web/aframe/8frame-0.9.2.min.js",// this seems to get added through requiring aframe
                '//cdn.8thwall.com/web/xrextras/xrextras.js',
                '//apps.8thwall.com/xrweb?appKey=ohLsrUz4LP0GTZ6LfgIAQ4eHpi2N0wMpolkYRVg3kWVvFoXvUhzh0BwC2Mw4IBqTB8QESz',
                'https://unpkg.com/aframe-look-at-component@0.8.0/dist/aframe-look-at-component.min.js'
            ];

            scripts.forEach(element => {
                let tag = document.createElement('script');
                tag.setAttribute('src', element);
                document.head.appendChild(tag);
            });
        }
    },
    
    mounted() {
        
    },

    methods: {
        
    }

}

</script>


<style lang="scss" scoped>

.ar-terrain {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-x: hidden;

    &.tablet {
        & .content {

            @media screen and (orientation:landscape) {
                display: none;
            }

        }

        & .orientation {

            @media screen and (orientation:landscape) {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-flow: column nowrap;
            }

        }
    }

    & .content {
        background: #deeaf7;
        position: absolute;
        width: 100%;
        height: 100%;

        & .landing-bg {
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }

    & .orientation {
        position: fixed;
        display: none;
        width: 100%;
        height: calc(100vh - 100px);
        width: 100vw;
        height: 100%;
        z-index: 100;
        transform: translateZ(10001px);
        object-fit: contain;

        & img {
            height: 100%;
            object-fit: contain;
        }

    }

    &.browser {
        
        & .orientation {
            display: none;
        }
    }

    &.mobile {
        & .content {

            @media screen and (orientation:landscape) {
                display: none;
            }
        }

        & .orientation {

            @media screen and (orientation:landscape) {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-flow: column nowrap;
            }
        }
    }
}

</style>
