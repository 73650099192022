<template>

    <div class="desktopLanding">
    
        <img class="bg" src="@/assets/images/ar-terrain/img-bg-desktop.webp">
        <h1>Planning Your Route</h1>
        <h2>In Augmented Reality</h2>
        <img src="@/assets/images/ar-terrain/img-desktop-phone.webp" class="phone">
        <div class="copy">
            <h3>Tashi delek!</h3>
            <h4>(Hello!)</h4>
            <p>This AR experience requires a<br><span>smart phone</span> or <span>tablet</span>.<br>Come back to this URL on your<br>mobile device, or <span>scan this QR code</span><br>to get started!</p>
            <img src="@/assets/images/ar-terrain/img-desktop-code.webp" class="phone">
            <p>Open Camera, Point, Click</p>
        </div>

    </div>

</template>


<script>

export default {

    name: 'DesktopLanding',
    
    data() {
        return {
            componentName: this.$options.name + ' Component'
        }
    },
    
    created() {
        
    },
    
    mounted() {
        
    },
    
    methods: {
        
    }
    
}

</script>


<style lang="scss" scoped>

.desktopLanding {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    & .bg {
        position: absolute;
        height: 100%;
        width: auto;

        @media (min-width: 576px) { // RED (SM)
            height: 100%;
            width: auto;
        }
        @media (min-width: 768px) { // GREEN (MD)

        }
        @media (min-width: 992px) { // BLUE (LG)

        }
        @media (min-width: 1200px) { // YELLOW (XL)

        }
        @media (min-width: 1400px) { // PURPLE (XXL)

        }
    }

    & h1 {
        position: relative;
        text-transform: uppercase;
        background: linear-gradient(to right, #20215e, #3a67a3, #67becc);
        color: #22226b;
        font-size: 70px;
        font-weight: $font-weight-extrabold;
        letter-spacing: 3px;
        margin: 72px 0 0;
        user-select: none;
        display: inline-block;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;

        @media (max-height: 950px) {
            font-size: 60px;
            margin: 52px 0 0;
        }

        @media (max-height: 800px) {
            font-size: 55px;
            margin: 32px 0 0;
        }

        @media (max-height: 700px) {
            font-size: 50px;
            margin: 28px 0 0;
        }

        @media (max-height: 600px) {
            font-size: 45px;
            margin: 24px 0 0;
        }
        
        @media (max-height: 500px) {
            font-size: 30px;
            margin: 20px 0 0;
        }
    }

    & h2 {
        color: #0f005d;
        position: relative;
        text-transform: uppercase;
        font-size: 30px;
        font-weight: $font-weight-bold;
        margin: 22px 0 0;

        @media (max-height: 950px) {
            font-size: 28px;
            margin: 20px 0 0;
        }

        @media (max-height: 800px) {
            font-size: 26px;
            margin: 15px 0 0;
        }

         @media (max-height: 700px) {
            font-size: 24px;
            margin: 12px 0 0;
        }

         @media (max-height: 600px) {
            font-size: 22px;
            margin: 8px 0 0;
        }
     
        @media (max-height: 500px) {
            font-size: 20px;
            margin: 5px 0 0;
        }
    }

    & .copy,
    & .phone {
        position: absolute;
        height: 59%;
        bottom: 0;
        margin-left: 28px;

        @media (max-height: 1180px) {
            height: 64%;
        }

        @media (max-height: 1050px) {
            height: 62%;
        }

        @media (max-height: 950px) {
            height: 68%;
        }

        @media (max-height: 850px) {
            height: 68%;
        }

        @media (max-height: 800px) {
            height: 68%;
        }

        @media (max-height: 750px) {
            height: 67%;
        }

        @media (max-height: 700px) {
            height: 65%;
            margin-left: 10px;
        }

        @media (min-width: 576px) { // RED (SM)
            height: 65%;
        }
        @media (min-width: 768px) { // GREEN (MD)
            height: 67%;
        }
        @media (min-width: 992px) { // BLUE (LG)
            height: 75%;
        }
    }

    & .copy {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 0;

        & h3 {
            color: #0f005d;
            font-size: 30px;
            font-weight: $font-weight-extrabold;
            margin: 5% 0 0 0;

            @media (max-height: 950px) {
                font-size: 25px;
                margin: 3% 0 0 0;
            }

            @media (max-height: 800px) {
                font-size: 20px;
                margin: 2% 0 0 0;
            }
            
            @media (max-height: 500px) {
                font-size: 15px;
                margin: 1% 0 0 0;
            }
        }

        & h4 {
            color: #00108f;
            font-size: 17px;
            font-weight: $font-weight-semibold;
            margin: .5% 0 0 0;

            @media (max-height: 950px) {
                font-size: 15px;
            }

            @media (max-height: 800px) {
                font-size: 13px;
            }
            
            @media (max-height: 500px) {
                font-size: 11px;
            }
        }

        & p {
            color: #424971;
            font-size: 17px;
            font-weight: $font-weight-regular;
            line-height: 30px;
            margin: 2% 0 0 0;
            padding: 0 100px;
            
            & span {
                font-weight: $font-weight-bold;
            }

            @media (max-height: 950px) {
                font-size: 15px;
                line-height: 25px;
                margin: 1.5% 0 0 0;
            }

            @media (max-height: 800px) {
                font-size: 14px;
                line-height: 20px;
                margin: 1% 0 0 0;
            }

            @media (max-height: 700px) {
                font-size: 13px;
                line-height: 17px;
                margin: .9% 0 0 0;
            }

            @media (max-height: 600px) {
                font-size: 12px;
                line-height: 15px;
                margin: .7% 0 0 0;
            }

            @media (max-height: 500px) {
                font-size: 9px;
                line-height: 12px;
                margin: .5% 0 0 0;
            }
            
            @media (max-height: 400px) {
                font-size: 7.5px;
                line-height: 12px;
                margin: .5% 0 0 0;
            }
        }

        & p:nth-child(5) {
            color: #00108f;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: $font-weight-bold;
            margin: .5% 0 0 0;

            @media (max-height: 950px) {
                font-size: 11px;
            }

            @media (max-height: 800px) {
                font-size: 9px;
            }
            
            @media (max-height: 500px) {
                font-size: 7px;
            }
        }

        & img {
            position: relative;
            width: auto;
            height: 25%;
            margin: 1% 0 0 0;

            @media (max-height: 950px) {
                margin: .8% 0 0 0;
            }

            @media (max-height: 800px) {
                margin: .4% 0 0 0;
            }
            
            @media (max-height: 500px) {
                margin: .2% 0 0 0;
            }
        }
    }
}

</style>